<template>
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    class="css-1ij4w9j"
  >
    <path
      d="M13.7124 10.7893L20.4133 3H18.8254L13.0071 9.7633L8.35992 3H3L10.0274 13.2273L3 21.3955H4.58799L10.7324 14.2533L15.6401 21.3955H21L13.7121 10.7893H13.7124ZM11.5375 13.3174L10.8255 12.299L5.16017 4.19542H7.59922L12.1712 10.7353L12.8832 11.7537L18.8262 20.2545H16.3871L11.5375 13.3178V13.3174Z"
      fill="currentColor"
    ></path>
  </svg>
</template>
