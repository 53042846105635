<template>
  <div class="app-bottom-wrapper">
    <div class="bottom-container">
      <div class="content-main">
        <div class="left-part-content">
          <div class="bottom-logo-wrapper">
            <NuxtLink to="/">
              <LogoIcon />
            </NuxtLink>
          </div>
          <div class="summary-text-wrapper">
            We believe that artificial inteligence is a technology that promotes
            human productivity and market change
          </div>
          <!-- <div class="social-contact-wrapper">
            <div class="social-item-wp">
              <a href="https://twitter.com/RootlistAI" target="_blank">
                <Twitter />
              </a>
            </div>
            <div class="social-item-wp width-28">
              <a href="https://rootlist.slack.com/" target="_blank">
                <SlackIcon />
              </a>
            </div>
            <div class="social-item-wp">
              <a
                href="https://www.linkedin.com/company/messari"
                target="_blank"
              >
                <Linked />
              </a>
            </div>
          </div> -->
        </div>
        <ul class="right-part-content">
          <li
            :class="{ open: activeList === 1 }"
            @click="activeList === 1 ? (activeList = 0) : (activeList = 1)"
          >
            <h4>Product</h4>
            <NuxtLink to="/projects/submit" :class="[ route.path === '/projects/submit' ? 'active' : '']">Project Submit</NuxtLink>
            <NuxtLink to="/feedback" :class="[ route.path === '/feedback' ? 'active' : '']">Feedback</NuxtLink>
          </li>
          <li
            :class="{ open: activeList === 2 }"
            @click="activeList === 2 ? (activeList = 0) : (activeList = 2)"
          >
            <h4>About</h4>
            <NuxtLink to="/about" :class="[ route.path === '/about' ? 'active' : '']">About Us</NuxtLink>
            <NuxtLink to="/terms" :class="[ route.path === '/terms' ? 'active' : '']">Terms</NuxtLink>
            <NuxtLink to="/privacy" :class="[ route.path === '/privacy' ? 'active' : '']">Privacy</NuxtLink>
          </li>
          <li
            :class="{ open: activeList === 3 }"
            @click="activeList === 3 ? (activeList = 0) : (activeList = 3)"
          >
            <h4>Contact</h4>
            <NuxtLink to="/about" :class="[ route.path === '/contact' ? 'active' : '']">Contact Us</NuxtLink>
            <!-- <NuxtLink to="/business">Business</NuxtLink> -->
            <a href="mailto:contact@rootlist.com">Business</a>
          </li>
          <li
            :class="{ open: activeList === 4 }"
            @click="activeList === 4 ? (activeList = 0) : (activeList = 4)"
          >
            <h4>Resources</h4>
            <NuxtLink to="/brand" :class="[ route.path === '/brand' ? 'active' : '']">Brand Assets</NuxtLink>
          </li>
        </ul>
      </div>
      <div class="content-bottom">
        <div class="website-registration">
          © {{ $dayjs().format("YYYY") }} RooList.com All Rights Reserved
        </div>
        <div class="bottom-right-warpper">
          <!-- <a class="status-item-wp" :href="`https://rootlist.com`">
            <div class="status-dot"></div>
            <div class="status-text">Status</div>
          </a> -->
          <div class="social-contact-wrapper">
            <div class="social-item-wp">
              <a href="https://twitter.com/RootlistAI" target="_blank">
                <Twitter />
              </a>
            </div>
            <div class="social-item-wp width-28">
              <a href="https://rootlist.slack.com/" target="_blank">
                <SlackIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { ref } from "vue";
import { useRoute } from "#imports";
import LogoIcon from "./icons/Logo.vue";

const route = useRoute();

let activeList = ref(0);
</script>
<style scoped lang="scss">
.app-bottom-wrapper {
  box-sizing: border-box;
  width: 100%;
  background: #141414;
  color: white;
  .bottom-container {
    width: 1280px;
    padding-top: 40px;
    padding-bottom: 40px;
    margin: 0 auto;
    .content-main {
      display: flex;
      justify-content: space-between;
      align-items: stretch;
      flex-wrap: wrap-reverse;
      .left-part-content {
        flex-shrink: 0;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        width: 400px;
        margin-right: 200px;
        .bottom-logo-wrapper {
          height: 40px;
          width: 201.3;
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          a {
            height: 40px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            svg {
              height: 40px;
              width: 201.3px;
            }
          }
        }
        .summary-text-wrapper {
          padding-top: 16px;
          font-weight: 400;
          font-size: 14px;
          color: rgba(255, 255, 255, 0.5);
          text-align: left;
          line-height: 24px;
        }
        .social-contact-wrapper {
          padding-top: 20px;
          padding-bottom: 40px;
          display: grid;
          grid-auto-flow: column;
          gap: 18px;
          .social-item-wp {
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.width-28 {
              width: 20px;
              height: 20px;
            }
            a {
              height: 100%;
              width: 100%;
              display: inline-block;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              svg {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
      .right-part-content {
        flex-shrink: 0;
        display: grid;
        justify-items: end;
        grid-auto-flow: column;
        gap: 120px;
        li {
          display: flex;
          flex-direction: column;
          text-align: left;
          h4 {
            color: #fff;
            font-size: 16px;
            font-weight: 600;
            margin: 0 0 20px 0;
          }
          a {
            font-size: 14px;
            font-weight: 500;
            color: rgba(232, 236, 239, 0.8);
            margin-bottom: 16px;
            cursor: pointer;
            &:hover{
              color: #1EC174 !important;
            }
            &.active{
              color: #1EC174 !important;
            }
          }
        }
      }
    }
    .content-bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .website-registration {
        font-weight: 400;
        font-size: 14px;
        color: #ffffff;
        line-height: 20px;
        text-align: left;
      }
      .bottom-right-warpper {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        .status-item-wp {
          display: inline-flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          .status-dot {
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #1ec174;
            margin-right: 5px;
          }
          .status-text {
            font-weight: 400;
            font-size: 14px;
            color: #ffffff;
          }
        }
        .social-contact-wrapper {
          display: grid;
          grid-auto-flow: column;
          gap: 18px;
          .social-item-wp {
            height: 20px;
            width: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            &.width-28 {
              width: 20px;
              height: 20px;
            }
            a {
              height: 100%;
              width: 100%;
              display: inline-block;
              justify-content: center;
              align-items: center;
              color: #ffffff;
              svg {
                height: 100%;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
// @media screen and (max-width: 930px) {
//   .app-bottom-wrapper {
//     width: 100%;
//     background-color: rgb(15, 23, 42, 1);
//     color: white;
//     padding: 40px;
//     .bottom-container {
//       .content-main {
//         flex-direction: column-reverse;
//         padding: 0;
//         .left-part-content {
//           padding: 20px 16px;
//           width: auto;
//         }
//         .right-part-content {
//           border-bottom: 1px solid #263441;
//           padding: 16px;
//           min-width: 0;
//           display: block;
//           li {
//             overflow: hidden;
//             padding-bottom: 10px;
//             h4 {
//               position: relative;
//               width: 100%;
//               &::after {
//                 content: "";
//                 transition: all 0.3s;
//                 position: absolute;
//                 top: 50%;
//                 right: 10px;
//                 width: 14px;
//                 height: 14px;
//                 transform: translateY(-50%);
//                 background: url("~/assets/images/dropdown.png") no-repeat 50%;
//                 background-size: contain;
//                 transform: translateY(-50%);
//                 -webkit-transform-origin: center;
//                 transform-origin: center;
//               }
//             }
//             a {
//               height: 0;
//               margin-bottom: 0;
//               overflow: hidden;
//               transition: all 0.3s;
//             }
//             &.open {
//               a {
//                 height: 24px;
//                 line-height: 24px;
//                 margin-bottom: 10px;
//               }
//               h4 {
//                 &::after {
//                   content: "";
//                   position: absolute;
//                   top: 40%;
//                   width: 14px;
//                   height: 14px;
//                   background: url("~/assets/images/dropdown.png") no-repeat 50%;
//                   background-size: contain;
//                   transform: translateY(-50%) rotate(-180deg);
//                   -webkit-transform-origin: center;
//                   transform-origin: center;
//                 }
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
</style>
